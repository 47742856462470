import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import { sanitizeEntity } from './sanitize';
import { types as sdkTypes } from '../util/sdkLoader';
import Decimal from 'decimal.js';
import { timestampToDate } from './dates';
import { ACCESS_OPTIONS, DEFAULT_CURRENCY, DELIVERY_FEE, REQUIRED_UPFRONT, SHIPPING_FEE } from './constants';
import { signupCountries } from '../config/configDefault';
import { isSupportedCurrency } from './currency';
import { supportedCurrencies } from '../config/configStripe';
import moment from 'moment';

// NOTE: This file imports sanitize.js, which may lead to circular dependency

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error(
      'Cannot merge resource objects with different ids or types'
    );
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes
    ? { attributes: { ...attributesOld, ...attributesNew } }
    : null;
  const relationships = combinedRelationships(
    oldRes.relationships,
    newRes.relationships
  );
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (
  oldEntities,
  apiResponse,
  sanitizeConfig = {}
) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr, sanitizeConfig);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity
      ? combinedResourceObjects({ ...entity }, current)
      : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (
  entities,
  resources,
  throwIfNotFound = true
) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(
          `Entity with type "${type}" and id "${id ? id.uuid : id}" not found`
        );
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' &&
        value?._ref?.type === 'imageAsset' &&
        value?._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef =
        typeof value == 'object' && value?._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (
  transaction,
  booking = null,
  listing = null,
  provider = null
) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = {
    id: null,
    type: 'currentUser',
    attributes: { profile: {} },
    profileImage: {},
  };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasDisplayName = user?.attributes?.profile?.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (
  objValue,
  srcValue,
  key,
  object,
  source,
  stack
) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

/**
 * A utility function to store data into session storage
 * @param {*} data
 * @param {*} storageKey
 */

export const storeDataToSession = (data, storageKey) => {
  if (window && window.sessionStorage && data) {
    const replacer = function(k, v) {
      if (this[k] instanceof Date) {
        return { date: v, _serializedType: 'SerializableDate' };
      }
      if (this[k] instanceof Decimal) {
        return { decimal: v, _serializedType: 'SerializableDecimal' };
      }
      return sdkTypes.replacer(k, v);
    };

    const storableData = JSON.stringify(
      {
        data,
        storedAt: new Date(),
      },
      replacer
    );
    window.sessionStorage.setItem(storageKey, storableData);
  }
};

export const getSessionStorageData = key => {
  if (window && window.sessionStorage && key) {
    const data = window.sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }
};

export const clearSessionItem = key => {
  if (window && window.sessionStorage && key) {
    window.sessionStorage.clear(key);
  }
};

export function setRentalDateLocalStorage(data) {
  const { dates, package: selectedPackage } = data;
  const {
    bookingStartDate,
    bookingEndDate,
    bookingStartTime,
    bookingEndTime,
  } = dates;

  const startDateMaybe =
    bookingStartDate instanceof Date ? { bookingStartDate } : {};
  const endDateMaybe = bookingEndDate instanceof Date ? { bookingEndDate } : {};
  const startTimeMaybe =
    bookingStartTime && timestampToDate(bookingStartTime) instanceof Date
      ? { bookingStartTime }
      : {};
  const endTimeMaybe =
    bookingEndTime && timestampToDate(bookingEndTime) instanceof Date
      ? { bookingEndTime }
      : {};

  const storableDates = {
    ...startDateMaybe,
    ...endDateMaybe,
    ...startTimeMaybe,
    ...endTimeMaybe,
  };

  const currentDate = new Date();
  const expirationTime = currentDate.getTime() + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  const storageData = {
    dates: storableDates,
    selectedPackage,
    expiresAt: expirationTime,
  };

  localStorage.setItem(selectedPackage, JSON.stringify(storageData));
}

export function getRentalDateFromLocalStorage(selectedPackage) {
  const storedData = localStorage.getItem(selectedPackage);
  if (storedData) {
    const { dates, selectedPackage, expiresAt } = JSON.parse(storedData);
    const currentDate = new Date().getTime();

    // Check if stored date is within 24 hours
    if (currentDate < expiresAt) {
      return { dates, selectedPackage }; // Return stored date if valid
    }
  }

  // Remove expired or invalid data from localStorage
  localStorage.removeItem(selectedPackage);
  return null; // Return null if no valid date found or expired
}

export function normalizeString(input) {
  // Replace underscores and hyphens with spaces
  let normalized = input.replace(/[_-]/g, ' ');

  // Convert camelCase to normal by adding space before uppercase letters
  normalized = normalized.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter of each word
  normalized = normalized.replace(/\b\w/g, char => char.toUpperCase());

  // Return the final normalized string
  return normalized;
}



export const buildQueryString = (params) =>  {
  // Filter out undefined or null values and build query parts dynamically
  const queryParts = Object.keys(params)
  .filter(key => params[key] !== undefined && params[key] !== null)
  .map(key => {
      const value = params[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });
  
// Join all the parts with '&' and prepend with '?'
return queryParts.length > 0 ? `?${queryParts.join('&')}` : null;
}



export const getAdditionalFeesValues = ({ usageMultipleCharges, values }) => {

   // create usage multiple values
   const requiredUsageMultipleCharges = usageMultipleCharges.filter(charge => charge.whenToApply === REQUIRED_UPFRONT);
   const usageMultipleChargesKeys = usageMultipleCharges.map(charge => charge.feeType);
   const usageMultipleValuesKeys = Object.keys(values || {})
     .filter(key => usageMultipleChargesKeys.includes(key));

   // Ensure all required charges have been filled
   const filledAllRequiredCharges = requiredUsageMultipleCharges.every(
     charge => usageMultipleValuesKeys.includes(charge.feeType)
   );

   // Build usage multiple values object
   const usageMultipleValues = usageMultipleValuesKeys.reduce((acc, key) => {
     acc[key] = values[key];
     return acc;
   }, {});

   // Other optional fees
   const { otherOptionalFees } = values;

   return {
     filledAllRequiredCharges,
     usageMultipleValues,
     otherOptionalFees
   }

}

export function createBoundingBox(lat, lng, radiusInKm) {
  const kmToDeg = radiusInKm / 111;
  const center = [lng, lat];

  return [
    center[1] - kmToDeg,  center[0] - kmToDeg, //southwest
    center[1] + kmToDeg, center[0] + kmToDeg, //northeast
  ];
}


/**
 * Sort packages in a specific order.
 *
 * The order is determined as follows:
 * 1. The default package (determined by the `defaultPackageKey` parameter)
 * 2. Per minute price
 * 3. Per hour price
 * 4. Per day price
 * 5. Overnight price
 * 6. Two days price
 * 7. One week price
 * 8. Twenty eight days price
 *
 * If a package is not present in the `packages` object, it is not included in the output.
 *
 * @param {Object} packages The packages object to sort.
 * @param {String} defaultPackageKey The key of the default package in the `packages` object.
 *
 * @returns {Object} The sorted packages object.
 */
export const sortPackages = (packages, defaultPackageKey) => {
  // Create a copy of the packages object
  const packagesCopy = { ...packages };

  // Extract the default package
  const defaultPackage = packagesCopy[defaultPackageKey];

  // Delete the default package from the copied object
  delete packagesCopy[defaultPackageKey];

  // Return the sorted packages
  return {
    ...(defaultPackage ? { [defaultPackageKey]: defaultPackage } : {}),
    ...(packagesCopy.perMinutePrice ? { perMinutePrice: packagesCopy.perMinutePrice } : {}),
    ...(packagesCopy.perHourPrice ? { perHourPrice: packagesCopy.perHourPrice } : {}),
    ...(packagesCopy.perDayPrice ? { perDayPrice: packagesCopy.perDayPrice } : {}),
    ...(packagesCopy.overnightPrice ? { overnightPrice: packagesCopy.overnightPrice } : {}),
    ...(packagesCopy.twoDaysPrice ? { twoDaysPrice: packagesCopy.twoDaysPrice } : {}),
    ...(packagesCopy.oneWeekPrice ? { oneWeekPrice: packagesCopy.oneWeekPrice } : {}),
    ...(packagesCopy.twentyEightDaysPrice ? { twentyEightDaysPrice: packagesCopy.twentyEightDaysPrice } : {})
  };
};

export const isPickupRequired = (accessOptions = []) => {
  return accessOptions.includes(ACCESS_OPTIONS.PICKUP);
};

export const isDeliveryOrShippingAvailable = (accessOptions = [], additionalFees = []) => {
  return accessOptions.includes(ACCESS_OPTIONS.DELIVERY) ||
    accessOptions.includes(ACCESS_OPTIONS.SHIP) ||
    additionalFees.find(fee => fee.feeType === SHIPPING_FEE) ||
    additionalFees.find(fee => fee.feeType === DELIVERY_FEE);
};

export const getUserDefaultCurrency = countryCode => {
  const userCountry = signupCountries.find(
    s => s.code === countryCode?.toUpperCase()
  );

  const supportedCurrency = isSupportedCurrency(
    supportedCurrencies,
    userCountry?.currency
  );

  return supportedCurrency ? userCountry.currency : DEFAULT_CURRENCY;
}; 

const PER_DAY = 'perDayPrice';
const PER_MINUTE = 'perMinutePrice';
const PER_HOUR = 'perHourPrice';
const TWO_DAYS = 'twoDaysPrice';
const WEEKLY_PRICE = 'oneWeekPrice';
const OVERNIGHT_PRICE = 'overnightPrice';
const TWENTY_EIGHT_DAYS = 'twentyEightDaysPrice';

export const getRentalPackage = (params) => {
  const { bookingStart, bookingEnd , selectedPackage, pricePackages } = params;

  const totalDays = moment(bookingEnd).diff(moment(bookingStart), 'days');

  if (totalDays >= 28 && pricePackages[TWENTY_EIGHT_DAYS]) {
    return TWENTY_EIGHT_DAYS;
  }

  if (totalDays >= 7 && pricePackages[WEEKLY_PRICE]) {
    return WEEKLY_PRICE;
  }

  if (totalDays >= 2 && pricePackages[TWO_DAYS]) {
    return TWO_DAYS;
  }

  if (totalDays >= 1 && selectedPackage === OVERNIGHT_PRICE && pricePackages[OVERNIGHT_PRICE]) {
    return OVERNIGHT_PRICE;
  }

  if (totalDays >= 1 && pricePackages[PER_DAY]) {
    return PER_DAY;
  }

  return selectedPackage; // Or handle other cases if needed
};

export const isWithinNinetyDays = ({ timeZone, bookingEnd }) => {

  if (!bookingEnd) {
    return false; // Return false if dates are missing
  }

  // Get current time in the given time zone (or default to UTC/local)
  const now = timeZone ? moment().tz(timeZone) : moment();
  const days = 88; //Subtract the 2 days buffer
  const ninetyDaysFromNow = now.clone().add(days, 'days');

  // Parse end dates with the same time zone
  const endDate = timeZone ? moment.tz(bookingEnd, timeZone) : moment(bookingEnd);

  // Ensure both end dates are within the future 90-day range
  return (
    endDate.isBefore(ninetyDaysFromNow) // End must be within 90 days
  );
};


export const getCurrentLocationCoords = () => {
  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(
      pos => {
        const data = {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        };
        resolve(data);
      },
      () => {
        resolve(null);
      }
    );
  });
}