import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldRadioButton, FieldTextInput, FieldSelect, FieldPhoneNumberInput, FieldLocationAutocompleteInput, Modal, FieldDateRangeInput, FieldCheckbox, AspectRatioWrapper, ResponsiveImage, H4, IconClose } from '../../../components';

import css from './SearchTeam.module.css';
import { BUDGET_FIRM_OPTION, BUDGET_FLEXIBLE_OPTION, BUDGET_RANGE_OPTION, budgetOptions, dateOptions, deliverPickupOptions, DELIVERY_NO, DELIVERY_NOT_APPLICABLE, DELIVERY_YES, durations, FLEXIBLE_DATE, FLEXIBLE_DURATION, radiusOptions, SPECIFIC_DATE, SPECIFIC_DURATION, timeToConnectOptions, urgencyFieldOptions, wayToConnectOptions } from '../../../util/constants';
import { isScrollingDisabled, manageDisableScrolling } from '../../../ducks/ui.duck';
import { connect } from 'react-redux';
import { useSearchTeamUploadImage, useSearchTeam, useSearchTeamRemoveImage } from '../../../ducks/searchTeam.duck';
import { Cloudinary } from "@cloudinary/url-gen";
import moment from 'moment';
import { getCloudinarySecureUrl } from '../../../util/cloudinary';

const cld = new Cloudinary({
    cloud: {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD
    }
});


// Field component that uses file-input to allow user to select images.
const FieldAddImage = props => {
    const {
        formApi,
        onImageUploadHandler,
        aspectWidth = 1,
        aspectHeight = 1,
        ...rest
    } = props;

    return (
        <Field form={null} {...rest}>
            {fieldprops => {
                const {
                    accept,
                    input,
                    topLabel,
                    bottomLabel,
                    disabled: fieldDisabled,
                    values,
                } = fieldprops;
                const { name, type } = input;
                const onChange = e => {
                    const file = e.target.files[0];
                    formApi.change(`addImage`, file);
                    formApi.blur(`addImage`);
                    onImageUploadHandler(file);
                };
                const inputProps = { accept, id: name, name, onChange, type };
                return (
                    <div className={css.addImageWrapper}>
                        <span className={css.topLabel}>{topLabel}</span>
                        <AspectRatioWrapper className={css.aspectRatio} width={aspectWidth} height={aspectHeight}>
                            {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                            )}
                            <label htmlFor={name} className={css.addImage}>
                                {bottomLabel}
                            </label>
                        </AspectRatioWrapper>
                    </div>
                );
            }}
        </Field>
    );
};

const FieldMinMax = props => {
    const { intl, currentUserCurrency: currency } = props;
    return (
        <div className={css.minMax}>
            <FieldTextInput
                className={css.min}
                id="min"
                name="minBudget"
                type="number"
                label={intl.formatMessage({ id: "SearchTeamForm.minLabel" }, { currency })}
                validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
            />
            <FieldTextInput
                className={css.min}
                id="max"
                name="maxBudget"
                type="number"
                label={intl.formatMessage({ id: "SearchTeamForm.maxLabel" }, { currency })}
                validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
            />
        </div>
    )
};

const ACCEPT_IMAGES = 'image/*';


const mapDataToSendgridSearchTeamTemp = ({ 
    values, 
    imageUrl, 
    currentUserCurrency, 
    intl 
}) => {
    const { 
        urgency, 
        duration, 
        days, 
        hours, 
        date, 
        specificDate, 
        location,
        delivery,
        deliveryAddress,
        sameAsAbove,
        radius,
        itemDescription,
        budget,
        firmBudgetAmount,
        minBudget,
        maxBudget,
        additionalInformation,
        name,
        phoneNumber,
        email,
        wayToConnect,
        bestTimeToConnect 
    } = values;

    const { startDate, endDate } = specificDate || {};
    const formattedStartDate = startDate ? moment(startDate).format('MMMM Do YYYY, h:mm:ss a') : null;
    const formattedEndDate = endDate ? moment(endDate).format('MMMM Do YYYY, h:mm:ss a') : null;

    const formattedFirmBudgetAmount = firmBudgetAmount
        ? intl.formatNumber(firmBudgetAmount, { style: 'currency', currency: currentUserCurrency })
        : null;
    const formattedMinBudgetAmount = minBudget
        ? intl.formatNumber(minBudget, { style: 'currency', currency: currentUserCurrency })
        : null;
    const formattedMaxBudgetAmount = maxBudget
        ? intl.formatNumber(maxBudget, { style: 'currency', currency: currentUserCurrency })
        : null;

    const userBudget = {
        ...(formattedFirmBudgetAmount ? { amount: formattedFirmBudgetAmount } : {}),
        ...(formattedMinBudgetAmount && formattedMaxBudgetAmount ? { range: { min: formattedMinBudgetAmount, max: formattedMaxBudgetAmount } } : {})
    };

    const locationMaybe = location?.selectedPlace ? { location: { ...location.selectedPlace } } : {};
    const deliveryAddressMaybe = deliveryAddress?.selectedPlace ? { deliveryAddress: { ...deliveryAddress.selectedPlace } } : {};
    const sameAsAboveMaybe = sameAsAbove?.length ? { sameAsAbove: sameAsAbove[0] } : {};

    const contact = { name, phone: phoneNumber, email };

    return {
        urgency,
        duration,
        days,
        hours,
        radius,
        itemDescription,
        imageUrl,
        additionalInformation,
        contact,
        bestTimeToConnect,
        userName: name,
        dateType: date,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        deliveryOption: delivery,
        budgetType: budget,
        budget: userBudget,
        bestWayToConnect: wayToConnect,
        ...deliveryAddressMaybe,
        ...sameAsAboveMaybe,
        ...locationMaybe
    };
};


const SearchTeamComponent = props => {
    const { 
        onUseSearchTeam,
        setIsSearchTeamModal,
        image,
        onManageDisableScrolling,
        isOpen,
        onClose,
        currentUserCurrency,
        intl
     } = props;

    const onSubmitSearchTeamForm = (values, formApi) => {
        const mappedData = mapDataToSendgridSearchTeamTemp({ values, imageUrl: image?.secure_url, currentUserCurrency, intl });

        const templateId = process.env.REACT_APP_SENDGRID_SEARCH_TEAM_TEM_ID;
        const to  = process.env.REACT_APP_SEARCH_TEAM_EMAIL;

        onUseSearchTeam({
            dynamic_template_data: mappedData,
            to,
            template_id: templateId,
        })
            .then(() => {
                setIsSearchTeamModal(false);
                // Clear form values and errors manually
                formApi.restart();
            })
    };

    return (
        <Modal id="SearchTeamForm" onManageDisableScrolling={onManageDisableScrolling} isOpen={isOpen} onClose={onClose} className={css.searchTeamModal}>
            <FinalForm
                {...props}
                mutators={{ ...arrayMutators }}
                onSubmit={(values, form) => onSubmitSearchTeamForm(values, form)}
                render={fieldRenderProps => {
                    const {
                        rootClassName,
                        className,
                        formId,
                        handleSubmit,
                        inProgress,
                        invalid,
                        intl,
                        values,
                        form,
                        currentUserCurrency,
                        requestSearchTeamHelpInProgress,
                        requestSearchTeamHelpError,
                        onSearchTeamImageUpload,
                        onSearchTeamRemoveImage,
                        imageUploadInProgress,
                        imageUploadError,
                        image,
                        currentUserLocationInfo,
                    } = fieldRenderProps;
                    const { origin } = currentUserLocationInfo || {};
                    const proximityMaybe = origin && origin.lat && origin.lng
                        ? { proximity: [origin.lng, origin.lat] }
                        : {}; 
          
                    const formSpyChangeHandler = ({ values: spyValues, dirty }) => {
                        if (!dirty) return;

                        const { duration, date, delivery, sameAsAbove, budget } = spyValues;

                        // Clear 'days' and 'hours' when flexible duration is selected
                        if (duration === FLEXIBLE_DURATION) {
                            form.change('days', undefined);
                            form.change('hours', undefined);
                        }

                        // Clear 'specificDate' when flexible date is selected
                        if (date === FLEXIBLE_DATE) {
                            form.change('specificDate', undefined);
                        }

                        // Clear 'deliveryAddress' if 'sameAsAbove' is true
                        if (sameAsAbove?.length) {
                            form.change('deliveryAddress', undefined);
                        }

                        // Handle delivery-related conditions
                        if ([DELIVERY_NO, DELIVERY_NOT_APPLICABLE].includes(delivery)) {
                            form.change('deliveryAddress', undefined);
                            form.change('sameAsAbove', undefined);
                        }

                        if ([DELIVERY_YES, DELIVERY_NOT_APPLICABLE].includes(delivery)) {
                            form.change('radius', undefined);
                        }

                        // Handle budget-related conditions using a switch statement
                        switch (budget) {
                            case BUDGET_FLEXIBLE_OPTION:
                                form.change('firmBudgetAmount', undefined);
                                form.change('minBudget', undefined);
                                form.change('maxBudget', undefined);
                                break;
                            case BUDGET_FIRM_OPTION:
                                form.change('minBudget', undefined);
                                form.change('maxBudget', undefined);
                                break;
                            case BUDGET_RANGE_OPTION:
                                form.change('firmBudgetAmount', undefined);
                                break;
                            default:
                                break;
                        }
                    };

                    const classes = classNames(rootClassName || css.root, className);
                    const submitInProgress = requestSearchTeamHelpInProgress;
                    const submitDisabled = invalid || requestSearchTeamHelpInProgress;
                    // console.log(values, 'vv');
                    return (
                        <Form className={classes} onSubmit={handleSubmit}>
                            <FormSpy onChange={formSpyChangeHandler} subscription={{ values: true, dirty: true }} />
                            <H4 className={css.mainTitle}><FormattedMessage id='SearchTeamForm.title'/></H4>
                            <p className={css.formIntro}><FormattedMessage id='SearchTeamForm.formIntroduction' /></p>
                            {!!requestSearchTeamHelpError ? <p className={css.error}><FormattedMessage id='SearchTeamForm.requestSearchTeamHelpError' /></p> : null}
                            {/* Urgency Level */}
                            <div className={css.field}>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.urgencyLevel' /></h4>
                                <label><FormattedMessage id='SearchTeamForm.urgencyFieldTitle' /></label>
                                {urgencyFieldOptions.map((option, index) => (
                                    <FieldRadioButton
                                        key={index}
                                        id={`urgency-id${index}`}
                                        name="urgency"
                                        label={option.label}
                                        value={option.value}
                                        validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                    />
                                ))}
                            </div>

                            {/* Duration */}
                            <div className={css.field}>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.duration' /></h4>
                                <label><FormattedMessage id='SearchTeamForm.durationFieldTitle' /></label>
                                {durations.map((option, index) => (
                                    <FieldRadioButton
                                        key={index}
                                        id={`duration-id${index}`}
                                        name="duration"
                                        label={option.label}
                                        value={option.value}
                                        validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                    />
                                ))}
                            </div>

                            {values.duration === SPECIFIC_DURATION
                                ? (
                                    <div className={css.daysAndHours}>
                                        <FieldTextInput
                                            className={css.field}
                                            type="number"
                                            id="days"
                                            name="days"
                                            label={intl.formatMessage({ id: "SearchTeamForm.daysLabel" })}
                                            validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                        />
                                        <FieldTextInput
                                            className={css.field}
                                            type="number"
                                            id="hours"
                                            name="hours"
                                            label={intl.formatMessage({ id: "SearchTeamForm.hoursLabel" })}
                                            validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                        />
                                    </div>
                                )
                                : null}


                            {/* Date Required */}
                            <div>
                                <div className={css.field}>
                                    <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.date' /></h4>
                                    <label><FormattedMessage id='SearchTeamForm.datesFieldTitle' /></label>
                                    {dateOptions.map((option, index) => (
                                        <FieldRadioButton
                                            key={index}
                                            id={`date-id${index}`}
                                            name="date"
                                            label={option.label}
                                            value={option.value}
                                            validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                        />
                                    ))}
                                </div>
                                {values.date === SPECIFIC_DATE
                                    ? (<FieldDateRangeInput
                                        className={css.datefield}
                                        name="specificDate"
                                        isDaily={true}
                                        startDateId='specificDate.startDate'
                                        startDateLabel='Start date'
                                        endDateId='specificDate.endDate'
                                        endDateLabel='End date'
                                        format={(v) => v}
                                        isBlockedBetween={() => false}
                                        isDayBlocked={() => () => false}
                                        isOutsideRange={() => () => false}
                                        validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                    />
                                    ) : null}
                            </div>

                            {/* Location */}
                            <div>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.location' /></h4>
                                <FieldLocationAutocompleteInput
                                    rootClassName={css.field}
                                    name="location"
                                    notShowIcon
                                    label={intl.formatMessage({ id: 'SearchTeamForm.locationFieldTilte' })}
                                    placeholder={intl.formatMessage({
                                        id: 'SearchTeamForm.locationFieldPlaceholder',
                                    })}
                                    useDefaultPredictions={false}
                                    format={(v) => v}
                                    valueFromForm={values.location}
                                    validate={validators.composeValidators(
                                        validators.autocompleteSearchRequired(intl.formatMessage({ id: "SearchTeamForm.addressRequired" })),
                                        validators.autocompletePlaceSelected(intl.formatMessage({ id: "SearchTeamForm.addressNotRecognized" }))
                                    )}
                                    {...proximityMaybe}
                                />
                            </div>

                            {/* Delivery Options */}
                            <div className={css.field}>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.delivery' /></h4>
                                <label><FormattedMessage id='SearchTeamForm.deliveryOrPickup' /></label>
                                {deliverPickupOptions.map(option => (<FieldRadioButton
                                    key={option.value}
                                    id={`delivery-${option.value}`}
                                    name="delivery"
                                    label={option.label}
                                    value={option.value}
                                    validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                />))}
                            </div>

                            {/* If Yes for delivery ask for location or same as above*/}
                            {values.delivery === DELIVERY_YES
                                ? (<div className={css.field}>
                                    {!values.sameAsAbove?.length
                                        ? (<FieldLocationAutocompleteInput
                                            rootClassName={css.deliveryAddress}
                                            name="deliveryAddress"
                                            notShowIcon
                                            label={intl.formatMessage({ id: 'SearchTeamForm.deliveryAddressLabel' })}
                                            placeholder={intl.formatMessage({
                                                id: 'SearchTeamForm.deliveryAddressPlaceholder',
                                            })}
                                            useDefaultPredictions={false}
                                            format={(v) => v}
                                            valueFromForm={values.deliveryAddress}
                                            validate={validators.composeValidators(
                                                validators.autocompletePlaceSelected(intl.formatMessage({ id: "SearchTeamForm.addressNotRecognized" }))
                                            )}
                                            disabled={values.sameAsAbove && values.sameAsAbove.length}
                                            {...proximityMaybe}
                                        />) : null}
                                    <FieldCheckbox
                                        id="same-as-above"
                                        name="sameAsAbove"
                                        label={intl.formatMessage({ id: "SearchTeamForm.sameLocationLabel" })}
                                        value="Same as above"
                                    />
                                </div>)
                                : values.delivery === DELIVERY_NO
                                    ? (<FieldSelect
                                        id="radius"
                                        name="radius"
                                        label={intl.formatMessage({ id: "SearchTeamForm.radiusFieldLabel" })}
                                        validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                    >
                                        {radiusOptions.map(opt => <option value={opt.value}>{opt.label}</option>)}
                                    </FieldSelect>)
                                    : null}

                            {/* Item Description */}
                            <div>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.itemDescription' /></h4>
                                <FieldTextInput
                                    className={css.field}
                                    id="itemDescription"
                                    name="itemDescription"
                                    type="textarea"
                                    label={intl.formatMessage({ id: "SearchTeamForm.itemDescriptionLabel" })}
                                    validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                />
                            </div>


                            {/* Image */}
                            <div>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.uploadPhoto' /></h4>
                                {image && image.public_id
                                    ? (<div className={css.image}>
                                        <ResponsiveImage
                                            renderCloudinaryImage={{
                                                secure_url: image.secure_url,
                                                maxWidth: '200px',
                                                height: '200px',
                                                alt:image.public_id,
                                            }}
                                        />
                                        <button
                                            className={css.removeImage}
                                            type='button'
                                            onClick={() => onSearchTeamRemoveImage(image.public_id)}
                                        >
                                            <IconClose size="large" />
                                        </button>
                                    </div>) : (
                                        <div>
                                            <FieldAddImage
                                                id="addImage"
                                                name="addImage"
                                                accept={ACCEPT_IMAGES}
                                                topLabel={intl.formatMessage({ id: "SearchTeamForm.imageInstructions" })}
                                                bottomLabel={
                                                    <span className={css.chooseImageText}>
                                                        <span className={css.imageTypes}>
                                                            <FormattedMessage id="SearchTeamForm.imageTypes" />
                                                        </span>
                                                    </span>
                                                }
                                                type="file"
                                                formApi={form}
                                                onImageUploadHandler={(f) => onSearchTeamImageUpload(f)}
                                                aspectWidth={1}
                                                aspectHeight={1}
                                                values={values}
                                            />
                                            {!!imageUploadError
                                                ? <span className={css.error}><FormattedMessage id="SearchTeamForm.imageUploadError" /></span>
                                                : null}
                                        </div>
                                    )}
                            </div>

                            {/* Budget */}
                            <div>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.budget' /></h4>
                                <div className={css.field}>
                                    <label><FormattedMessage id='SearchTeamForm.budgetFieldTitle' /></label>
                                    {budgetOptions.map(option => (<FieldRadioButton
                                        key={option.value}
                                        id={`budget-${option.value}`}
                                        name="budget"
                                        label={option.label}
                                        value={option.value}
                                        validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                    />))}
                                </div>
                                {values.budget === BUDGET_FIRM_OPTION
                                    ? (<div className={css.fiel}>
                                        <FieldTextInput
                                            className={css.field}
                                            id="firmBudgetAmount"
                                            name="firmBudgetAmount"
                                            type="number"
                                            label={intl.formatMessage({ id: "SearchTeamForm.firmBudgetAmountLabel" }, { currency: currentUserCurrency })}
                                            validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                        />
                                    </div>) : null}
                                {values.budget === BUDGET_RANGE_OPTION
                                    ? (<div className={css.field}>
                                        <FieldMinMax values={values} intl={intl} currentUserCurrency={currentUserCurrency} />
                                    </div>)
                                    : null}
                            </div>

                            {/* Additional Information */}
                            <div>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.additionalInformation' /></h4>
                                <FieldTextInput
                                    className={css.field}
                                    id="additionalInformation"
                                    name="additionalInformation"
                                    type="textarea"
                                    label={intl.formatMessage({ id: "SearchTeamForm.additionalInformationTitle" })}
                                    validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                />
                            </div>


                            {/* Contact Information */}
                            <div>
                                <h4 className={css.fieldsTitle}><FormattedMessage id='SearchTeamForm.contactInformation' /></h4>
                                <FieldTextInput
                                    className={css.field}
                                    id="name"
                                    name="name"
                                    type="text"
                                    label={intl.formatMessage({ id: "SearchTeamForm.nameFieldLabel" })}
                                    validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                />
                                <FieldPhoneNumberInput
                                    className={css.field}
                                    id={`phoneNumber`}
                                    name="phoneNumber"
                                    label={intl.formatMessage({ id: "SearchTeamForm.phoneFieldLabel" })}
                                    placeholder={intl.formatMessage({ id: "SearchTeamForm.phoneFieldPlaceholder" })}
                                    validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                />

                                <FieldTextInput
                                    className={css.field}
                                    type="email"
                                    id={'email'}
                                    name="email"
                                    autoComplete="email"
                                    label={intl.formatMessage({
                                        id: 'SearchTeamForm.emailFieldLabel',
                                    })}
                                    placeholder={intl.formatMessage({
                                        id: 'SearchTeamForm.emailPlaceholder',
                                    })}
                                    validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                />
                            </div>

                            <div className={css.field}>
                                <labe className={css.question}><FormattedMessage id='SearchTeamForm.wayToConnectFieldTitle' /></labe>
                                {wayToConnectOptions.map((opt, index) => (
                                    <FieldCheckbox
                                        id={opt.label}
                                        name="wayToConnect"
                                        label={opt.label}
                                        value={opt.value}
                                        validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                    />)
                                )}
                            </div>

                            {/* Best time to connect */}
                            <div className={classNames(css.lastField)}>
                                <FieldSelect
                                    id="bestTimeToConnect"
                                    name="bestTimeToConnect"
                                    label={intl.formatMessage({ id: "SearcgTeamForm.bestTimeToConnectFieldTitle" })}
                                    validate={validators.required(intl.formatMessage({ id: "SearchTeamForm.required" }))}
                                >
                                    {timeToConnectOptions.map(opt => <option value={opt.value}>{opt.label}</option>)}
                                </FieldSelect>
                            </div>

                            <div className={css.bottomWrapper}>
                                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                                    <FormattedMessage id="SearchTeamForm.submit" />
                                </PrimaryButton>
                            </div>
                        </Form>
                    );
                }}
            />
        </Modal>
    )
};

SearchTeamComponent.defaultProps = { inProgress: false };

SearchTeamComponent.propTypes = {
    inProgress: bool,
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { currentUserCurrency, currentUserLocationInfo } = state.user;
    const {
        requestSearchTeamHelpInProgress,
        requestSearchTeamHelpError,
        imageUploadInProgress,
        imageUploadError,
        image,
    } = state.useSearchTeam;

    return {
        scrollingDisabled: isScrollingDisabled(state),
        currentUserCurrency,
        currentUserLocationInfo,
        requestSearchTeamHelpInProgress,
        requestSearchTeamHelpError,
        imageUploadInProgress,
        imageUploadError,
        image
    };
};

const mapDispatchToProps = dispatch => ({
    onManageDisableScrolling: (componentId, disableScrolling) =>
        dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSearchTeamImageUpload: (file) =>
        dispatch(useSearchTeamUploadImage(file)),
    onSearchTeamRemoveImage: (public_id) =>
        dispatch(useSearchTeamRemoveImage(public_id)),
    onUseSearchTeam: (data) =>
        dispatch(useSearchTeam(data))
});

const SearchTeamForm = compose(connect(
    mapStateToProps,
    mapDispatchToProps
), injectIntl)(SearchTeamComponent);

SearchTeamForm.displayName = 'SearchTeamForm';

export default SearchTeamForm;